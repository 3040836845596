.slides {
  width: 100%;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  margin-top: 40px;
}

.slider {
  margin-top: -30px;
}

.bullet {
  color: #fff;
  font-size: 12px;
  line-height: 10px;
  margin-bottom: 80px;
  position: relative;
  &:before {
    content: ' ';
    border-radius: 20px;
    border: 2px solid $palm-green;
    background-color: $colour-primary;
    height: 28px;
    width: 28px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: 0 auto;
    margin-top: 15px;
  }
  &--orange:before {
    background-color: $orange;
  }
  &:after {
    content: ' ';
    border-radius: 20px;
    background-color: #fff;
    height: 10px;
    width: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: 0 auto;
    margin-top: 25px;
  }
  .h-line {
    //&:after {
    //   content: '';
    //   z-index: -1;
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   margin: 0 auto;
    //   margin-top: 23px;
    //   width: 2px;
    //   height: 90px;
    //   background-color: rgba(255, 255, 255, 0.5);
    // }
  }
}

.slide__item {
  padding: 0 20px;
  width: 380px;
}

.slides__item {
  background-color: white;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 2px 20px 0 rgba(24, 24, 24, 0.3);
  position: relative;
  text-align: left;
  display: block;
  transition: all ease 0.2s;
  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 159px;
    background: linear-gradient(180deg, rgba(24, 24, 24, 0) 0%, rgba(24, 24, 24, 0.8) 100%);
    z-index: 1;
  }
  .title {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 2;
  }
  .typeblock {
    display: inline-block;
    padding: 5px 10px;
    font-size: 12px;
    margin-bottom: 20px;
    transition: all ease 0.2s;
  }
  .video_button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
    zoom: 1;
    transition: all ease 0.4s;
  }
  &:hover {
    box-shadow: 0 2px 40px 0 rgba(24, 24, 24, 0.4);
    .typeblock {
      margin-left: 5px;
    }
    .video_button {
      width: 55px;
    }
  }
}

.sliderArrows__left {
  left: 20px;
  top: -35px;
  z-index: 999;
  cursor: pointer;
}

.sliderArrows__right {
  right: 20px;
  top: -35px;
  z-index: 999;
  cursor: pointer;
}
