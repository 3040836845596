input, textarea, select {
    border: 1px solid $black;
    height: inherit;
    background-color: $white;
    margin-bottom: 20px;
    font-family: 'Open Sans';
}

select, input {
    height: 50px;
}

textarea {
    height: 300px;
    line-height: 25px;
}

label {
    padding-bottom: 10px;
    display: block;
    font-weight: 700;
}

input, textarea {
    border: 2px solid rgba(126,126,126,0.5);
    padding-left: 10px;
    border-radius: 4px;
    margin-right: 20px;
    &:focus {
        outline: 0;
        border: 2px solid $colour-primary;
    }
}

form {
    margin-top: 20px;
    button {
        background-color: $colour-primary;
        padding: 9px 20px;
        color: #fff;
    }
}

input[type="checkbox"] {
    appearance: checkbox;
    height: inherit;
}

input[type="submit"] {
    background: #17d05b;
    color: #ffffff;
    border: 5px solid #17d05b;
    border-image: url("/img/site/button-bg-160.svg") 3 round;
    border-image-outset: 3px;
    border-image-width: 6px;
}

button[type="submit"] {
    background: #17d05b;
    border: 5px solid #17d05b;
    border-image: url("/img/site/button-bg-160.svg") 3 round;
    border-image-outset: 3px;
    padding: 4px 20px;
}

