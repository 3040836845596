.faq {
    hr {
        height: 1px;
    }

    &__item {
        height: auto;

        &.active {
            .faq__question {
                &:after {
                    transform: rotate(90deg);
                }

                &:before {
                    transform: rotate(180deg);
                }
            }

            .faq__answer {
                max-height: 700px;
                transition: all .5s;
            }
        }
    }

    &__question {
        padding: 16px 24px;
        position: relative;
        max-width: 98%;
        cursor: pointer;

        h5 {
            margin: 0;
            padding: 0;
        }

        &:after {
            position: absolute;
            content: '';
            right: 24px;
            top: 16px;
            background-color: white;
            height: 16px;
            width: 2px;
            margin-top: 5px;
            transition: all ease-in-out .3s;
        }

        &:before {
            position: absolute;
            content: '';
            background-color: white;
            right: 17px;
            top: 23px;
            height: 2px;
            width: 16px;
            margin-top: 5px;
            transition: all ease-in-out .3s;
        }
    }

    &__answer {
        padding: 0 24px;
        overflow: hidden;
        max-height: 0;
        transition: all .5s;
    }
}

.toggleCollapsItems {
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    span {
        font-weight: bold;
        color: #1D1D1D;
    }

    img {
        margin-left: auto;
        width: 10px;
        height: 10px;
        transition: .3s all;
    }

    &.active {
        img {
            transform: rotate(90deg);
        }
    }
}

.collapseItems {
    transition: .5s all;
    overflow: hidden;
    height: 0;
    opacity: 0;

    .faq__question {
        padding: 16px;
        max-width: 100%;
    }

    .faq__answer {
        padding: 0 16px;
    }

}

.js-readmore {
    transition: .5s all;
    overflow: hidden;
    height: 0;
    opacity: 0;
}

.readmoreToggle {
    margin-bottom: 40px;
    transition: .3s all;
    @media only screen and (min-width: 767px) {
        margin-bottom: 0;
    }
    &.active {
        margin-bottom: 0;
        &:after {
            transform: rotate(90deg);
        }
    }
}
