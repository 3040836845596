/*
* At the moment this just contains some variables but can easily expand to
* include a modular scale etc.
*/

$base-font-family: 'Open Sans', sans-serif;
$header-font-family: 'DDC ash', sans-serif;
$header2-font-family: 'Open Sans', sans-serif;
$base-line-height: 1.8;
$base-font-size: 15px;
