.news {
  padding: 30px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  @media only screen and (min-width: $small) {
    flex-direction: row;
  }

  @media only screen and (min-width: $medium) {
    justify-content: space-evenly;
  }

  &--card {
    padding: 0;

    @media only screen and (min-width: $large) {
      padding: 0 80px;
    }
  }

}


.news-date {
  background: $colour-primary;
  padding: 10px 15px;
  position: absolute;
  color: $white;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.5;
  z-index: 2;
}

.overview {
  margin-bottom: 30px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex: 0 0 100%;
  max-width: 100%;

  @media only screen and (min-width: $small) {
    flex: 0 0 49%;
    max-width: 49%;
  }
  @media only screen and (min-width: $medium) {
    flex: 0 0 30%;
    max-width: 30%;
  }

  &__imageLink {
    display: inline-table;
    overflow: hidden;
    width: 100%;

    &--img {
      background-size: cover;
      background-position: center center;
      height: 250px;
      transition: .4s ease-in-out;
      background-blend-mode: overlay;
      background-color: rgba(25, 25, 25, 0.2);
      width: 100%;

      &:hover {
        background-blend-mode: overlay;
        background-color: rgba(25, 25, 25, 0);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    flex: 0 0 100%;
    max-width: 100%;
    max-height: 100%;

    &--title {
      padding: 15px;
      text-align: center;

      p {
        color: #ef3e4a;
        margin: 0;
      }

      h2 {
        margin: 0;
      }
    }

    &--text {
      padding: 0 0 15px;
      position: relative;
      text-align: center;

      p {
        margin: 0;
        padding-bottom: 30px;
      }

      img {
        height: 32.3px;
      }

      a {
        font-weight: 800;
      }
    }
  }
  &--card {
    margin-bottom: 30px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 0 0 100%;
    max-width: 100%;

    @media only screen and (min-width: $medium) {
      flex: 0 0 47%;
      max-width: 47%;
    }
  }

  &--full {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.overview-fix {
  min-height: 140px;
  padding: 15px;
}
