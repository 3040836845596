.video-wrap {
    padding-bottom: 56.25%;
    position: relative;
    text-align: center;

    iframe {
    	width: 100%;
    	height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
    }
}

.embed-video {
    height: 300px;

    @media only screen and (min-width: $medium) {
        height: 480px;
    }
}
