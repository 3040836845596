// Navigation
nav {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  height: auto;
  background-color: #FAFAFA;
  z-index: 9999;
  @media only screen and (min-width: 768px) {
    height: 60px;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    padding: 0;
    margin: 0;
    display: block;
    &:before {
      content: '';
    }
  }
  .instagram {
    background-color: #23cf5f;
    height: 100%;
    display: inline-block;
    padding: 10px;
    @media only screen and (min-width: 768px) {
      padding: 20px;
      height: 60px;
      display: inline;
    }
  }
}

#fixed-nav {
  position: relative;
  z-index: 998;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  .toggle {
    background: $palm-green;
    width: 100%;
    height: 100%;

    img:nth-child(2) {
      display: none;
    }

    &.open {
      img:nth-child(1) {
        display: none;
      }
      img:nth-child(2) {
        display: block;
      }
    }

    img {
      height: 22px;
    }
  }

  @media only screen and (min-width: $medium) {
    height: 80px;
  }
}

.languages {
  img {
    border-radius: 22px;
    padding: 2px;
    border: 2px solid #fff;
    &.active {
      border: 2px solid $colour-primary;
    }
  }
  .instagram img {
    border: 0;
    padding: 0;
    border-radius: 0;
  }
}

.menu {
  text-align: right;
  width: 100%;
  margin: 0;
  padding-left: 0;
  opacity: 1;
  z-index: 99;
  top: 48px;
  bottom: 0;
  left: 0;
  right: 0;
  &__holder {
    position: absolute;
    width: 100%;
    z-index: 9999;
    &.collapse {
      .menu {
        opacity: 1;
      }
    }
    @media only screen and (min-width: 768px) {
      position: static;
      width: auto;
      background-color: transparent;
    }
  }
  @media only screen and (min-width: 768px) {
    background-color: inherit;
    margin: 0 auto;
    opacity: 1;
    text-align: center;
    position: static;
    &.collapse {
      background-color: inherit;
      position: static;
    }
  }
  &__list {
    text-align: center;
    padding: 0;
    margin-top: 2vh;
    font-weight: 600;

    .active {
      color: $palm-green;
    }

    @media only screen and (min-width: 768px) {
      display: inline-block;
      // margin: 0 3%;
      margin: 0 1.5%;
      font-weight: 700;
    }
    &:before {
      content: none;
    }
    &.has-children {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 12px;
        right: -12px;
        height: 5px;
        width: 8px;
        background-image: url('/assets/images/dropdown.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
      .nav__level2 {
        opacity: 0;
        @media only screen and (min-width: 768px) {
          display: none !important;
        }
        display: none;
        position: absolute;
        top: 0;
        padding: 45px 10px 20px;
        left: -22px;
        width: 200px;
        background-color: white;
        color: black;
        z-index: 999;
        li {
          float: left;
          width: 100%;
          display: block;
          margin-bottom: 5px;
          text-align: left;
          padding-left: 10px;
          a {
            &:after {
              content: none;
            }
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    &__item {
      font-weight: 600;
      color: $nav-black;
    }
  }
}

.nav-mobile {
  height: 0;
  overflow: hidden;
  z-index: -1;
}

.insta-feedText {
  color: $colour-primary;
  font-size: 18px;
  font-family: $header-font-family;
}
