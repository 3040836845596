.footer {
    background-color: #f8fbff;
    .vragen {
        margin-top: rem-calc(-160px);
    }
    .footer-menu {
        &__item {
            font-weight: bold;
            line-height: 24px;
            margin-right: 10px;
            display: block;
            @media only screen and (min-width: $medium) {
                display: inline;
            }
        }
    }
}

.instagram-caption {
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    overflow: hidden;
    a {
        color: $palm-green;
    }
}
