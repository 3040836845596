.modal {
  &__kolommen-img {
      max-height: 300px;
      overflow: hidden;
      display: flex;
      align-items: center;
  }
  &__ico {
    img {
      max-width: 100px;
    }
  }
  color: #fff;
  opacity: 1;
  visibility: visible;
  transition: all ease 0.4s;
  &.none {
    display: inline;
    opacity: 0;
    visibility: hidden;
    .modal__content {
      opacity: 0;
      visibility: hidden;
    }
  }
  &__content {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.4s;
    transition-property: all;
    transition-duration: 0.4s;
    &--email {
      color: #7E7E7E;
    }
  }
  .close-button {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    position: relative;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
    text-align: center;
    z-index: 999999999;
    padding-top: 11px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all ease 0.2s;
    &:hover {
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
    }
  }

  ul li {
    &:before {
      color: #ffffff;
    }
  }
}
