.tabs {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    .tablink {
        cursor: pointer;
        transition: .2s all;
        color: #1D1D1D;
        white-space: nowrap;
        padding: 13.5px 12px;
        font-size: 12px;

        @media only screen and (min-width: 767px) {
            padding: 27px 28px;
            font-size: 15px;
        }

        &:hover {
            color: #28d467;
        }

        &.active {
            color: #23CF5F;
        }
    }
}

.tabcontent {
    display: none;

    &--withTrans {
        animation: transContent 1.7s;

        @keyframes transContent {
            0% { opacity: 0; transform: translateX(10px); }
            50% {transform: translateX(0);}
            100% { opacity: 1; }
        }
    }
}

.cameraTabs {
    .tablink {
        cursor: pointer;
        padding: 16px 10px;
        transition: .2s all;
        color: #1D1D1D;
        background-color: #E9FAEF;
        white-space: nowrap;
        width: 100%;
        font-size: 14px;

        @media only screen and (min-width: 767px) {
            padding: 24px 10px;
            font-size: 15px;
        }

        &.active {
            background-color: #fff;
            color: #23CF5F;
        }
    }
}

