.item {
    padding-top: 10px;
    padding-bottom: 10px;
    @media only screen and (min-width: 768px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    &__image {
        // hi
    }
    &__text {
        h3 {
            margin-bottom: 12px;
            color: $palm-green;
        }
        @media only screen and (min-width: 768px){
            padding: 40px;
        }
    }
    &__quote h3{
        letter-spacing: 1px;
        @media only screen and (min-width: 768px) {
            letter-spacing: 2px;
        }
    }

    &--nieuwsbrief {
        .bg-colour-accent {
            a {
                color: $colour-primary;
            }
        }
    }
}
