.content-header {
    height: 320px;
    text-align: center;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    background-blend-mode: overlay;
    padding: 0 0 30px;
    background-position-y: center;

    @media only screen and (min-width: $medium) {
        height: 480px;
    }

    h1 {
        align-self: flex-end;
        max-width: 1200px;
    }
}

