.button {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    border-radius: 0;
    font-weight: 700;
    padding: 16px 30px;
    color: $white;
    text-decoration: none;
    border: 0;
    outline: none;
    font-size: 14px;
    &--white {
        color: $colour-primary;
        background-color: $white;
    }
    &--red {
        background-color: $colour-primary;
        color: $white;
    }
}

.readmore {
    font-family: $header-font-family;
    color: $colour-primary;
    font-size: 18px;

    &:after {
        content: "";
        transition: .3s all;
        background: url("/img/site/pijl-rechts-groen.svg") no-repeat;
        width: 50px;
        margin-left: .3em;
        height: 30px;
        background-position: center;
        position: absolute;
    }
}
