.container {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: $xlarge;
}

@media screen and (min-width: $xlarge) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.media-wrap {
	padding-bottom: 56.25%;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
	width: 100%;
}

.media-wrap {
	//$s is $key for $sizes map
	@each $s, $size in $sizes {
		&-#{$s} {
			max-width: #{$size};
		}
	}
}

.bg-square {
    height: 0;
    padding-bottom: 100%;
    width: 100%
}

.vh {
  //$s is value for $vhHeights map
  @each $s in $vhHeights {
    &-#{$s} {
      height: #{$s}vh;
    }
  }
}

@each $prefix, $size in $screens {
  @media screen and (min-width: $size) {
    .#{$prefix}\:vh {
      //$s is value for $vhHeights map
      @each $s in $vhHeights {
        &-#{$s} {
          height: #{$s}vh;
        }
      }
    }
  }
}
