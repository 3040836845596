.preload {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999999;
  opacity: 1;
  transition: all ease 0.4s;
}
.preload.loaded {
  opacity: 0;
  visibility: hidden;
}

@keyframes hideshow {
  0% { fill: #f6fdf9; }
  10% { fill: #f6fdf9; }
  15% { fill: #f6fdf9; }
  100% { fill: #23cf5f; }
}

.svg-leaf {
  fill: #f6fdf9;
  animation: hideshow 0.5s 0.5s ease forwards;
}

.svg-glass {
  fill: #f6fdf9;
  animation: hideshow 0.5s 0.4s ease forwards;
}

.svg-middle {
  fill: #f6fdf9;
  animation: hideshow 0.5s 0.2s ease forwards;
}

.svg-bottom {
  fill: #f6fdf9;
  animation: hideshow 0.5s 0s  ease forwards;
}

