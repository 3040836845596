p,
a,
li,
blockquote,
form,span {
    -webkit-font-smoothing: antialiased;
	font-weight: 500;
}

p {
	margin-bottom: 15px;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
	font-family: $header-font-family;
}

// as determined by design
h1,
.h1 {
	@include font-size(56px);
	line-height: rem-calc(72px);
	font-family: $header-font-family;
	letter-spacing: 2px;
}

@media screen and (max-width: $medium) {
    h1,
    .h1 {
    	@include font-size(33px);
    	line-height: rem-calc(40px);
    }
}

h2,
.h2 {
	@include font-size(35px);
	line-height: rem-calc(46px);
	font-family: $header-font-family;
	color: $palm-green;
}

@media screen and (max-width: $medium) {
	h2,
	.h2 {
		@include font-size(23px);
		line-height: rem-calc(30px);
	}
}

h3,
.h3 {
	@include font-size(34px);
	line-height: rem-calc(38px);
	font-family: $header-font-family;
	letter-spacing: 1px;
}

h3.quote {
    font-family: $header-font-family;
}

@media screen and (max-width: $medium) {
	h3,
	.h3 {
		@include font-size(23px);
		line-height: rem-calc(26px);
		font-family: $header-font-family;
	}
}

h4,
.h4 {
	@include font-size(22px);
	line-height: rem-calc(29px);
    font-family: $header2-font-family;
	letter-spacing: 1px;
}

@media screen and (max-width: $medium) {
	h4,
	.h4 {
		@include font-size(18px);
		line-height: rem-calc(23px);
	}
}

h5,
.h5 {
	@include font-size(19px);
	line-height: rem-calc(25px);
    font-family: $header2-font-family;
	letter-spacing: 0.5px;
}

@media screen and (max-width: $medium) {
	h5,
	.h5 {
		@include font-size(16px);
		line-height: rem-calc(21px);
	}
}

h6,
.h6 {
	@include font-size(20px);
	line-height: rem-calc(26px);
	font-family: $header2-font-family;
	color: #181818;
	font-weight: 600;
}

@media screen and (max-width: $medium) {
	h6,
	.h6 {
		@include font-size(14px);
		line-height: rem-calc(18px);
	}
}
